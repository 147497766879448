<template>
  <div class="editor-page-wrap">
    <b-container class="mt-2 mb-5 px-5">
      <b-row class="draft__top">
        <b-col cols="6" class="draft__top-text">
          <h4>Drafts</h4>
          <p>Create stunning contents to share on social media or export to PDF. Edit existing or start a new one.</p>
        </b-col>
        <b-col class="draft__top-action-btn">
          <b-button :to="{ name: 'draft.edit', params: { id: 0 } }" variant="primary" size="lg" class="px-3">
            Create New
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <!-- display loading screen  -->
          <b-row class="mt-3" cols="4" v-if="$apollo.queries.editorContents.loading">
            <b-col v-for="(d, i) in 12" :key="i">
              <skeleton-screens class="text-center" width="100%" height="230px" itemClass="content-item skeleton" />
            </b-col>
          </b-row>

          <!-- draft contents -->
          <div v-else-if="hasContents" class="py-2 draft__content">
            <div class="draft__content-header ml-4">My Saved Draft</div>
            <b-row cols="4">
              <b-col v-for="editorContent in editorContents.contents" :key="editorContent.id">
                <div class="draft__content-item my-3 mx-1">
                  <div class="draft__content-screenshot">
                    <router-link :to="{ name: 'draft.edit', params: { id: editorContent.id } }">
                      <div class="content-img" :style="{ backgroundImage: `url(${editorContent.screenshot})` }"></div>
                    </router-link>
                    <div class="content-meta">
                      <router-link :to="{ name: 'draft.edit', params: { id: editorContent.id } }" class="meta-title">
                        {{ editorContent.excerpt }}
                      </router-link>
                      <div class="meta-time"><span class="time-info">Edited</span> {{ editorContent.updatedAt }}</div>
                    </div>
                  </div>
                  <div class="draft__content-actions">
                    <b-button
                      v-b-tooltip.hover
                      title="Edit Draft"
                      :to="{ name: 'draft.edit', params: { id: editorContent.id } }"
                      variant="primary"
                      size="sm"
                      class="px-2"
                    >
                      <img src="@/assets/icons/new/edit.svg" alt="" />
                    </b-button>
                    <b-button
                      v-b-tooltip.hover
                      title="Delete Draft"
                      @click.prevent="confirmDeleteContent(editorContent)"
                      variant="primary"
                      size="sm"
                    >
                      <img src="@/assets/icons/new/trash.svg" alt="" />
                    </b-button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </div>

          <!-- if content is empty display create content banner -->
          <b-row v-else class="mt-3 bg-white shadow-sm rounded px-4 py-5  ">
            <b-col md="6">
              <div class="py-2 pl-5">
                <img class="pt-3" width="350" src="@/assets/images/online_page.svg" alt="" />
              </div>
            </b-col>
            <b-col md="6">
              <div class="py-5 px-3">
                <h3>Create Your First Draft</h3>
                <p>Create content for a product, promote a sale, share an inspiring story and more.</p>
                <b-button
                  :to="{ name: 'draft.edit', params: { id: 0 } }"
                  variant="outline-primary"
                  class="rounded-pill px-4 mt-3"
                  >Get started</b-button
                >
              </div>
            </b-col>
          </b-row>

          <!-- if content is empty display empty content banner -->
          <b-row
            class="mt-3 bg-white rounded-sm shadow-sm"
            v-if="!$apollo.queries.editorContents.loading && !hasContents"
          >
            <b-col class="text-center p-5 mt-4">
              <img class="pt-3" width="200" src="@/assets/images/no_data.svg" alt="" />
              <h5 class="pb-4 pt-3">No saved Drafts yet.</h5>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-modal ref="deleteModal" centered hide-footer hide-header>
        <div v-if="isDeleting" class="text-center py-5"><spinner size="4" /></div>
        <div v-else class="text-center py-5">
          <h4>Delete content?</h4>
          <p>Are you sure you would like to delete this content?</p>

          <b-button variant="light" size="sm" class="mr-3 px-4" @click="closeConfirmDeleteContent">Cancel</b-button>
          <b-button size="sm" class="px-4" :disabled="isDeleting" @click="actuallyDeleteContent">Delete</b-button>
        </div>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { EDITOR_CONTENTS_QUERY } from '~/graphql/queries';
import { DELETE_EDITOR_CONTENT_MUTATION } from '~/graphql/mutations';

export default {
  components: {},

  data() {
    return {
      editorContents: {},

      contentToDelete: null,
      isDeleting: false,
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
    hasContents() {
      return this.editorContents && this.editorContents.contents && this.editorContents.contents.length > 0;
    },
  },

  apollo: {
    editorContents: {
      query: EDITOR_CONTENTS_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          limit: 10,
        };
      },
    },
  },

  mounted() {
    if (this.$apollo.queries.editorContents) {
      this.$apollo.queries.editorContents.refetch();
    }

    const { Echo } = window;
    Echo.channel(`drafts`).listen('DraftScreenshotSaved', e => {
      const index = this.editorContents.contents.findIndex(content => content.id == e.id);
      if (index !== -1) {
        this.editorContents = {
          ...this.editorContents,
          contents: [
            ...this.editorContents.contents.slice(0, index),
            {
              ...this.editorContents.contents[index],
              screenshot: e.screenshot.sm,
            },
            ...this.editorContents.contents.slice(index + 1),
          ],
        };
      }
    });
  },

  beforeDestroy() {
    window.Echo.leave('drafts');
  },

  methods: {
    confirmDeleteContent(content) {
      this.contentToDelete = content;

      this.$refs.deleteModal.show();
    },

    closeConfirmDeleteContent() {
      this.contentToDelete = null;

      this.$refs.deleteModal.hide();
    },

    async actuallyDeleteContent() {
      this.isDeleting = true;
      await this.$apollo.mutate({
        mutation: DELETE_EDITOR_CONTENT_MUTATION,
        variables: {
          workspace: this.workspace.id,
          ids: [parseInt(this.contentToDelete.id)],
        },
      });

      this.isDeleting = false;
      this.closeConfirmDeleteContent();
      this.$apollo.queries.editorContents.refetch();
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.draft__top {
  margin-bottom: 10px;
  border: 2px solid $primary;
  border-radius: 10px;
  &-text {
    padding: 30px 0 30px 40px;
    color: $primary;
    h4 {
      font-weight: bold;
    }
    p {
      margin: 0;
      padding: 0;
      font-size: 14px;
    }
  }
  &-action-btn {
    background-image: url('../../assets/images/new/stroke.png');
    background-repeat: no-repeat;
    background-position: center top;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // button {
    //   line-height: 50px;
    //   vertical-align: middle;
    // }
  }
}
.draft__content {
  &-header {
    color: $primary;
    font-weight: bold;
    text-transform: capitalize;
    font-size: 24px;
  }
  &-item {
    width: 100%;
    cursor: pointer;
    &:hover {
      border-color: $primary;
      .content-meta {
        display: block;
      }
      .draft__content-actions {
        display: flex;
      }
    }
  }
  &-screenshot {
    display: block;
    margin-bottom: 10px;
    @include skeleton-animation(#f7f7f7);
    border-radius: 0.5rem;
    position: relative;
    .content-img {
      height: 200px;
      background-repeat: no-repeat;
      background-size: 200px auto;
    }
    .content-meta {
      display: none;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      border-radius: 0 0 0.5rem 0.5rem;
      text-align: center;
      background: $dark;
      .meta-title {
        color: #d8f3dc;
        display: block;
        font-size: 12px;
        letter-spacing: 0.15px;
        line-height: 18px;
        margin-left: 2px;
        vertical-align: top;
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-decoration: none;
        margin-bottom: 2px;
        padding: 8px;
        border-bottom: 2px solid #d8f3dc;
        &:hover {
          color: #d8f3dc;
          text-decoration: none;
        }
      }
      .meta-time {
        font-size: 12px;
        letter-spacing: 0.3px;
        vertical-align: middle;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 6px;
        color: #d8f3dc;
        .time-info {
          color: #52b788;
          font-weight: bold;
        }
      }
    }
  }
  &-actions {
    display: none;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    margin: 4px auto;
  }
}

.content-item {
  margin-bottom: 10px;
  .content-meta-container {
    border-top: 1px solid #e2e2e2;
    padding: 10px 22px 14px 14px;
    position: relative;

    .meta-right-container {
      width: 85%;
    }
  }
}
</style>
