<template>
  <DraftMasterView currentStep="1" :goBackHandler="goBackHandler">
    <template slot="buttons">
      <div class="d-flex align-center">
        <b-button
          class="px-2 mr-1 act-button"
          variant="clear"
          @click="saveEditorContent(false)"
          :disabled="isSaving || !selectedContent || (selectedContent && !selectedContent.body)"
        >
          <b-spinner v-if="isSaving" class="mr-1" small type="grow" />
          <svg-icon v-else name="check" class="mr-1" />
          Save
        </b-button>
        <b-button
          class="px-2 act-button"
          variant="primary"
          @click="saveEditorContent(true)"
          :disabled="isSaving || !selectedContent || (selectedContent && !selectedContent.body)"
        >
          <svg-icon name="share-alt" class="mr-1" small />
          Share
        </b-button>
      </div>
    </template>

    <b-container class="position-relative">
      <b-row class="mt-4">
        <b-col md="8" offset-md="2">
          <div class="editor-wrap bg-white p-5 shadow-sm">
            <div v-if="$apollo.queries.editorContentById.loading">
              <div class="text-center pt-5 mt-5"><spinner /></div>
            </div>
            <RichTextEditor
              v-else
              :content="selectedContent ? selectedContent.body : ''"
              @input="updateSelectedContent"
              :showFabs="true"
              placeholder="Create great content"
            />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </DraftMasterView>
</template>

<script>
import { mapState } from 'vuex';
import DraftMasterView from './master';
import RichTextEditor from '~/components/RichTextEditor';
import { FETCH_EDITOR_CONTENT_BY_ID_QUERY } from '~/graphql/queries';
import { SAVE_EDITOR_CONTENT_MUTATION } from '~/graphql/mutations';

export default {
  components: {
    DraftMasterView,
    RichTextEditor,
  },

  data() {
    return {
      isSaving: false,
      editorContentById: {},
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
      publishAccounts: state => state.createPost.publishAccounts,
      selectedContent: state => state.createPost.selectedContent,
      accountPostContents: state => state.createPost.accountPostContents,
    }),
  },

  apollo: {
    editorContentById: {
      query: FETCH_EDITOR_CONTENT_BY_ID_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          id: parseInt(this.$route.params.id, 10),
        };
      },
      result({ data }) {
        if (data.editorContentById) {
          this.$store.dispatch('createPost/updateSelectedContent', {
            body: data.editorContentById.content,
          });
        }
      },
    },
  },

  methods: {
    goBackHandler() {
      this.$router.push({ name: 'draft.overview' });
    },
    updateSelectedContent(content) {
      this.$store.dispatch('createPost/updateSelectedContent', {
        ...this.selectedContent,
        body: content,
      });
    },

    handleNext() {
      if (this.accountPostContents.length > 0 && this.selectedContent) {
        const newContents = this.accountPostContents.map(accountContent => {
          const contentItem = {
            ...accountContent,
            content: this.selectedContent.content,
            attachments: this.selectedImages,
          };

          const account = this.publishAccounts.find(account => account.id == accountContent.accountId);
          account && this.$store.dispatch('validation/validate', { account, postContent: contentItem });

          return contentItem;
        });
        this.$store.dispatch('createPost/updateAccountPostContents', newContents);
      }

      this.$router.push({ name: 'draft.accounts' });
    },

    async saveEditorContent(andNext = false) {
      this.isSaving = true;

      if (!this.selectedContent || (this.selectedContent && !this.selectedContent.body)) {
        return;
      }

      const id = parseInt(this.$route.params.id, 10);
      const { data } = await this.$apollo.mutate({
        mutation: SAVE_EDITOR_CONTENT_MUTATION,
        variables: {
          workspace: this.workspace.id,
          id,
          content: this.selectedContent.body,
        },
      });

      if (data.saved && !andNext) {
        this.$router.push({ name: 'draft.edit', params: { id: data.saved.id } });
      } else if (data.saved && andNext) {
        this.$router.push({ name: 'draft.share', params: { id: data.saved.id } });
      }

      this.isSaving = false;
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.editor-wrap {
  min-height: 100vh;
}
</style>
