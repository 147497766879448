<template>
  <div class="draft-page">
    <TopWizard :goBackHandler="goBackHandler" :sequence="sequence" :currentStep="currentStep" :sidebar="false">
      <div class="action-buttons" slot="buttons"><slot name="buttons" /></div>
    </TopWizard>

    <slot />
  </div>
</template>

<script>
import TopWizard from '~/components/TopWizard';

export default {
  components: {
    TopWizard,
  },

  props: {
    goBackHandler: {
      default: () => {},
    },

    sidebar: {
      type: Boolean,
      default: false,
    },

    currentStep: {
      type: [Number, String],
    },
  },

  data() {
    return {
      onBackBtn: false,
      sequence: [
        {
          number: 1,
          name: 'Create Content',
          to: { name: 'draft.edit' },
        },
        {
          number: 2,
          name: 'Share & Export',
          to: { name: 'draft.share' },
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import '~@/scss/variables';

.draft-page {
  padding-top: $topbar-height;
  padding-bottom: 70px;
  min-height: 100vh;
}
</style>
