<template>
  <DraftMasterView currentStep="2" :goBackHandler="goBackHandler">
    <b-container class="mt-5 position-relative">
      <div class="mt-4 share__container">
        <b-row class="mt-4">
          <b-col md="7" class="">
            <div class="share__container-left">
              <h3>Share Your Content</h3>
              <p class="m-0 p-0">Pick a platform to share your content.</p>

              <div class="share__container-left-btn m-3">
                <b-button
                  variant="clear"
                  class="mr-1"
                  :class="{ active: activeTab === 'one' }"
                  @click="setActiveTab('one')"
                >
                  <svg-icon name="medium" size="md" />
                  <span class="ml-2 title">Wordpress</span>
                </b-button>
                <b-button
                  variant="clear"
                  class="ml-1"
                  :class="{ active: activeTab === 'two' }"
                  @click="setActiveTab('two')"
                >
                  <svg-icon name="wordpress" size="md" />
                  <span class="ml-2 title">Medium</span>
                </b-button>
              </div>

              <transition name="platform-slide">
                <div v-if="activeTab === 'one'">
                  <div class="mt-4">
                    <p>Give your post a title, then share on your business’s Medium Page.</p>
                    <b-form-group label="Post title" label-for="medium-title">
                      <b-form-input id="medium-title" placeholder="Add a title" class="rounded-sm" />
                    </b-form-group>
                    <b-form-group label="Post tags" label-for="medium-tags">
                      <b-form-input
                        id="medium-tags"
                        placeholder="Add comma separated tags (optional)"
                        class="rounded-sm"
                      />
                    </b-form-group>
                    <b-form-group label="Canonical url" label-for="medium-canonical">
                      <b-form-input
                        id="medium-canonical"
                        placeholder="The original url of this content (optional)"
                        class="rounded-sm"
                      />
                    </b-form-group>

                    <div class="mt-5 d-flex justify-content-between align-items-center">
                      <b-button @click="exportPdf" :disabled="isSaving" variant="primary" class="px-2 rounded">
                        <b-spinner v-if="isSaving" class="ml-2" small type="grow" />
                        <svg-icon v-else name="download-alt" class="mr-2" size="md" />
                        Download PDF
                      </b-button>
                      <b-button variant="outline-primary" class="px-2 rounded">
                        <svg-icon name="arrow-right-alt" class="mr-2" size="md" />
                        Next
                      </b-button>
                    </div>
                  </div>
                </div>
                <div v-if="activeTab === 'two'">
                  <div class="mt-4">
                    <p>Configure your post, then share on your Wordpress Site.</p>

                    <b-form-group label="Post title" label-for="wordpress-title">
                      <b-form-input id="wordpress-title" placeholder="Add a title" class="rounded-sm" />
                    </b-form-group>
                    <b-form-group label="Post tags" label-for="wordpress-tags">
                      <b-form-input
                        id="wordpress-tags"
                        placeholder="Add comma separated tags (optional)"
                        class="rounded-sm"
                      />
                    </b-form-group>
                    <div class="clear-fix"></div>
                    <div class="mt-5 d-flex justify-content-between align-items-center">
                      <b-button @click="exportPdf" :disabled="isSaving" variant="primary" class="px-2 rounded">
                        <b-spinner v-if="isSaving" class="ml-2" small type="grow" />
                        <svg-icon v-else name="download-alt" class="mr-2" size="md" />
                        Download PDF
                      </b-button>
                      <b-button variant="outline-primary" class="px-2 rounded">
                        <svg-icon name="arrow-right-alt" class="mr-2" size="md" />
                        Next
                      </b-button>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </b-col>
          <b-col md="5" class="">
            <div class="share__container-right">
              <h3>Content Preview</h3>
              <div class="screenshot-bg">
                <div
                  class="share-page-content-img"
                  :style="{ backgroundImage: `url(${editorContentById.screenshot})` }"
                ></div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </DraftMasterView>
</template>

<script>
import { mapState } from 'vuex';
import DraftMasterView from './master';
import { FETCH_EDITOR_CONTENT_BY_ID_QUERY } from '~/graphql/queries';
import { EXPORT_EDITOR_CONTENT_AS_PDF_MUTATION } from '~/graphql/mutations';

export default {
  components: {
    DraftMasterView,
  },

  data() {
    return {
      isSaving: false,
      editorContentById: {},
      activeTab: 'one',
    };
  },

  computed: {
    ...mapState({
      workspace: state => state.workspace.current,
    }),
  },

  apollo: {
    editorContentById: {
      query: FETCH_EDITOR_CONTENT_BY_ID_QUERY,
      variables() {
        return {
          workspace: this.workspace.id,
          id: parseInt(this.$route.params.id, 10),
        };
      },
    },
  },

  mounted() {
    const { Echo } = window;
    const draftId = this.$route.params.id;

    Echo.private(`draft.${draftId}`).listen('DraftScreenshotSaved', e => {
      this.editorContentById.screenshot = e.screenshot.lg;
    });
  },

  methods: {
    setActiveTab(activeTab) {
      this.activeTab = activeTab;
    },

    goBackHandler() {
      this.$router.push({ name: 'draft.edit' });
    },
    async exportPdf() {
      if (!this.editorContentById || (this.editorContentById && !this.editorContentById.content)) {
        return;
      }

      this.isSaving = true;
      const id = parseInt(this.$route.params.id, 10);
      const { data } = await this.$apollo.mutate({
        mutation: EXPORT_EDITOR_CONTENT_AS_PDF_MUTATION,
        variables: {
          workspace: this.workspace.id,
          id,
          content: this.editorContentById.content,
        },
      });

      if (data.downloadUrl) {
        this.saveToDisk(data.downloadUrl, 'PDFExport.pdf');
      }

      this.isSaving = false;
    },

    saveToDisk(fileURL, fileName) {
      // for non-IE
      if (!window.ActiveXObject) {
        var save = document.createElement('a');
        save.href = fileURL;
        save.target = '_blank';
        save.download = fileName || 'unknown';

        var evt = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);

        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }

      // for IE < 11
      else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL);
        _window.close();
      }
    },
  },
};
</script>

<style lang="scss">
@import '~@/scss/mixins';
@import '~@/scss/variables';

.share__container {
  h3 {
    font-size: 18px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
  }
  &-left {
    border-radius: 20px;
    background: $white;
    padding: 20px 40px;
    margin-right: 2px;
    color: $primary;
    .clear-fix {
      margin: 120px auto;
    }
    &-btn {
      button {
        border: 2px solid $primary;
        padding: 10px;
        margin-bottom: 5px;
        .title {
          display: none;
        }
      }
      .active {
        background: $primary;
        transition: all 0.2s linear !important;
        svg {
          fill: #d8f3dc;
        }
        .title {
          transition: all 0.2s linear !important;
          color: #d8f3dc;
          display: inline-block;
        }
      }
    }
  }

  &-right {
    border-radius: 20px;
    background: #d8f3dc;
    border: 1px solid #52b788;
    margin-left: 2px;
    padding: 20px;
  }
}

.screenshot-bg {
  @include skeleton-animation($gray-200);
  border-radius: 20px;
}

.share-page-content-img {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  box-sizing: content-box;
  border-radius: 20px;
}
</style>
